import { DocumentType, TrackingType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { TrackingData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemTracking: FC<ListItemProps<TrackingType, TrackingData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<TrackingType>>();

  useEffect(() => {
    let isMounted = true;
    const unsub = model.watchById(docId, document => {
      if (isMounted) {
        setDoc(document);
      }
    });

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const description =
    doc.description && doc.description.length > 124
      ? `${doc.description.slice(0, 124)}...`
      : doc.description;

  const date = dayjs(doc.end).format('D MMMM YYYY');
  let duration = 0;
  if (doc.duration) {
    const [heures, minutes] = doc.duration.split(':');
    duration = parseInt(heures, 10) * 60 + parseInt(minutes, 10);
  }

  return (
    <ListItem
      description={description}
      displayActionId={false}
      doc={doc}
      index={index}
      label={`Suivi de ${duration}' fini le ${date}`}
      onClick={onClick}
    />
  );
};

export default ListItemTracking;
